export function formattedURL(value) {
    const length = value.length
    const isEndABar = value[length - 1] === '/'

    if (isEndABar) {
        return value.slice(0, value.length - 1)
    } else {
        return value
    }
}
