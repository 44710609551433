import { Api } from 'config'

export async function authenticate(payload) {
    const endpoint = `v1/alunos/auth/signin`
    const response = await Api.post(endpoint, null, {
        auth: {
            username: payload.login,
            password: payload.password,
        },
    })

    return response
}

export async function authenticateSSO(utmSource, token) {
    const endpoint = `/v1/alunos/auth/${utmSource}/sso/signIn`
    const response = await Api.post(endpoint,{}, {
      headers:{
        authorization: token
      }  
    })
    return response
}


export async function changePassword(payload) {
    const endpoint = `/password`
    const response = await Api.post(endpoint, payload)

    return response
}
export async function sendMail(payload) {
    const endpoint = `/send-mail`
    const response = await Api.post(endpoint, payload)

    return response
}

export async function recoveryPassword(payload) {
    const endpoint = `/recovery-pass/${encodeURIComponent(payload.token)}`
    const response = await Api.put(endpoint, payload)

    return response
}
