import encode from 'jwt-encode'
import decode from 'jwt-decode'

const secret = process.env.REACT_APP_JWT_SECRET

export function jwtEncode(value) {
    const jwt = encode(value, secret)
    return jwt
}
export function jwtDecode(value) {
    const data = decode(value)
    return data
}
