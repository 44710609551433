import { Api } from 'config'

export async function getTitulosDestaque() {
    const endpoint = `/titulos/destaque`
    const response = await Api.get(endpoint)

    return response
}

export async function getTitulos() {
    const endpoint = `/titulos`
    const response = await Api.get(endpoint)

    return response
}

export async function getTitulosVencidos() {
    const endpoint = `/vencidos`
    const response = await Api.get(endpoint)

    return response
}

export async function getTitulosV2() {
    const endpoint = `/v2/titulos`
    const response = await Api.get(endpoint)

    return response
}

export async function getTitulosDestaqueV2() {
    const endpoint = `/v2/titulos/destaque`
    const response = await Api.get(endpoint)

    return response
}
