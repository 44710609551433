import axios from 'axios'
import history from '../history'
import jwtDecode from 'jwt-decode'
import store from '../store'
import { onSignOutSuccess } from 'store/auth/sessionSlice'
import { initialState, setUser } from 'store/auth/userSlice'
import appConfig from 'configs/app.config'

const headers = { headers: { 'Content-Type': 'application/json' } }

let baseURL = ''
baseURL = process.env.REACT_APP_API_URL

const validateStatus = (status) => status < 400

const apiInstance = axios.create({
    baseURL,
    headers,
    validateStatus,
})

export const get = async (endpoint, params) => {
    try {
        grantAuthorization()
        const response = await apiInstance.get(endpoint, { params })
        const responseHeaders = response.headers

        return Handlesuccess(response.data, responseHeaders)
    } catch (error) {
        return handleError(error)
    }
}

export const post = async (endpoint, payload, config) => {
    try {
        grantAuthorization()

        const response = await apiInstance.post(endpoint, payload, config)
        const responseHeaders = response.headers

        return Handlesuccess(response.data, responseHeaders)

    } catch (error) {
        return handleError(error)
    }
}

export const put = async (endpoint, payload) => {
    try {
        const response = await apiInstance.put(endpoint, payload)
        const responseHeaders = response.headers

        return Handlesuccess(response.data, responseHeaders)
    } catch (error) {
        return handleError(error)
    }
}

export const deleteWithPayload = async (endpoint, payload) => {
    try {
        const response = await apiInstance.post(endpoint, payload, {})
        const responseHeaders = response.headers

        return Handlesuccess(response.data, responseHeaders)
    } catch (error) {
        return handleError(error)
    }
}

function handleError(error) {
    if (error.response) {
        if (error.response.status === 401) {
            //SessionService.finishSession();
            history.push('/')
            return Handlefailure(error.response.data.message)
        }

        if (typeof error.response.data.message === 'string')
            return Handlefailure(error.response.data.message)

        return Handlefailure('Erro não tratado')
    }
    if (error.request) {
        return Handlefailure('Nenhuma resposta foi obtida - api fora do ar.')
    }
    return Handlefailure(error.message)
}

export function grantAuthorization() {
    //apiInstance.defaults.headers.common.Authorization = `Bearer ${token}`;
    const token = sessionStorage.getItem('token')
    if (token) {
        const { exp } = jwtDecode(token)

        if (Date.now() > exp * 1000) {
            store.dispatch(onSignOutSuccess())
            store.dispatch(setUser(initialState))
            history.push(appConfig.unAuthenticatedEntryPath)
        }
        apiInstance.defaults.headers.common['x-access-token'] = token
    }
}

export function removeAuthorization() {
    delete apiInstance.defaults.headers.common.Authorization
}

function Handlesuccess(response, responseHeaders) {
    const obj = {
        ok: true,
        data: response,
        responseHeaders,
        error: undefined,
    }

    return obj
}

function Handlefailure(errorMessage) {
    const obj = {
        ok: false,
        data: undefined,
        error: {
            message: errorMessage,
        },
    }

    return obj
}
