import { Api } from 'config'

export async function getSimulacao(payload) {
    const endpoint = `/acordos/simular`
    const response = await Api.post(endpoint, payload)

    return response
}

export async function createAcordo(payload) {
    const endpoint = `/acordos/gerar`
    const response = await Api.post(endpoint, payload)

    return response
}

/**
 * Parcelas vencidas
 */

export async function createAcordoV2(payload) {
    const endpoint = `/v2/acordos/gerar`
    const response = await Api.post(endpoint, payload)

    return response
}

export async function cancelAcordoV2(payload) {
    const endpoint = `/v2/acordos/${payload}/cancelar`
    const response = await Api.post(endpoint, {})

    return response
}

/**
 * Parcela unica não vencida
 */

export async function createAcordoCartaoV2(payload) {
    const endpoint = `/v2/acordos/gerar/cartao`
    const response = await Api.post(endpoint, payload)

    return response
}

export async function getSimulacaoV2(payload) {
    const endpoint = `/v2/acordos/simular`
    const response = await Api.post(endpoint, payload)

    return response
}

export async function getSimulacaoRematricula(payload) {
    const endpoint = `/v2/acordos/simular/rematricula`
    const response = await Api.post(endpoint)

    return response
}

export async function createAcordoRematricula(payload) {
    const endpoint = `/v2/acordos/gerar/rematricula`
    const response = await Api.post(endpoint, payload)

    return response
}
