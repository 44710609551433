import { Api } from 'config'
import { setSessionCookie, removeSessionCookie } from 'store/cache/cookies'

export async function initSession(session) {
    setSessionCookie(session)

    Api.grantAuthorization(session.token)
}

export async function updateSession(session) {
    setSessionCookie(session)
}

export async function finishSession() {
    removeSessionCookie()
    Api.removeAuthorization()
}
