import { Api } from 'config'

export async function getDadosCadastro() {
    const endpoint = `/usuario`
    const response = await Api.get(endpoint)

    return response
}

export async function updateTokenEmail(payload) {
    const endpoint = `usuario/enviar-token-email`
    const response = await Api.post(endpoint, payload)

    return response
}

export async function updateEmail(payload) {
    const endpoint = `usuario/alterar-email`
    const response = await Api.put(endpoint, payload)

    return response
}
export async function criarDadosCelPrincipal(payload) {
    const endpoint = `usuario/criar-telefone-celular`
    const response = await Api.put(endpoint, payload)

    return response
}

export async function updateDadosCelPrincipal(payload) {
    const endpoint = `usuario/alterar-telefone-celular`
    const response = await Api.put(endpoint, payload)

    return response
}

export async function tokenCelular(payload) {
    const endpoint = `usuario/criar-token-sms`
    const response = await Api.post(endpoint, payload)

    return response
}

export async function updateTokenCelular(payload) {
    const endpoint = `usuario/alterar-token-sms`
    const response = await Api.put(endpoint, payload)

    return response
}
export async function criarDadosCelSecundario(payload) {
    const endpoint = `usuario/criar-telefone-celular`
    const response = await Api.put(endpoint, payload)

    return response
}

export async function updateDadosCelSecundario(payload) {
    const endpoint = `usuario/alterar-telefone-celular`
    const response = await Api.put(endpoint, payload)

    return response
}

export async function updateDadosTelefoneFixo(payload) {
    const endpoint = `usuario/alterar-telefone-fixo`
    const response = await Api.put(endpoint, payload)

    return response
}

export async function updateEndereco(payload) {
    const endpoint = `usuario/alterar-endereco`
    const response = await Api.put(endpoint, payload)

    return response
}
