import { useSelector, useDispatch } from 'react-redux'
import { setUser, initialState } from 'store/auth/userSlice'
import { apiSignUp } from 'services/mock/AuthService'
import { onSignInSuccess, onSignOutSuccess } from 'store/auth/sessionSlice'
import appConfig from 'configs/app.config'

import { useNavigate } from 'react-router-dom'

import { SessionService } from 'services'
import { jwtEncode } from 'utils/jwt'

import Cookies from 'js-cookie'
import { formattedURL } from 'utils/formatURL'

function useAuth() {
    const dispatch = useDispatch()

    const navigate = useNavigate()

    const { token, signedIn } = useSelector((state) => state.auth.session)
    const { user } = useSelector((state) => state.auth)

    const signIn = async (data) => {
        try {
            if (data) {
                const { token, ies } = data

                if (ies) {
                    if (ies.length === 1) {
                        const jwt = jwtEncode({ ...data, ies: ies[0] })

                        window.location.assign(
                            `${formattedURL(ies[0].url)}/redirect?auth=${jwt}`
                        )
                        return
                    }
                    if (ies.length > 1) {
                        const redirectToken = jwtEncode(data)
                        navigate(
                            `${appConfig.iesSelection}?auth=${redirectToken}`
                        )
                        return
                    }
                }

                if (data) {
                    dispatch(
                        setUser(
                            data || {
                                id: '',
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                                cpf: '',
                            }
                        )
                    )
                }

                const session = {
                    name: data.name,
                    email: data.email,
                    change_password: data.change_password,
                    ies: data.ies,
                    token: data.token,
                }

                sessionStorage.setItem('token', data.token)
                SessionService.initSession(session)

                dispatch(onSignInSuccess(token))

                if (data.change_password === true) {
                    navigate(appConfig.firstAcess)
                    // return
                } else {
                    navigate(appConfig.authenticatedEntryPath)
                }
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const signUp = async (values) => {
        try {
            const resp = await apiSignUp(values)
            if (resp.data) {
                const { token } = resp.data
                dispatch(onSignInSuccess(token))
                if (resp.data.user) {
                    dispatch(
                        setUser(
                            resp.data.user || {
                                avatar: '',
                                userName: 'Anonymous',
                                authority: ['USER'],
                                email: '',
                            }
                        )
                    )
                }
                // const redirectUrl = query.get(REDIRECT_URL_KEY)
                // navigate(
                //     redirectUrl ? redirectUrl : appConfig.authenticatedEntryPath
                // )
                return {
                    status: 'success',
                    message: '',
                }
            }
        } catch (errors) {
            return {
                status: 'failed',
                message: errors?.response?.data?.message || errors.toString(),
            }
        }
    }

    const handleSignOut = () => {
        dispatch(onSignOutSuccess())
        dispatch(setUser(initialState))
        navigate(appConfig.unAuthenticatedEntryPath)
    }

    const signOut = async () => {
        // await apiSignOut()
        SessionService.finishSession()
        handleSignOut()
    }

    return {
        authenticated: token && signedIn,
        signIn,
        signUp,
        signOut,
    }
}

export default useAuth
