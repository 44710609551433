const appConfig = {
    apiPrefix: '/api',
    authenticatedEntryPath: '/home',
    firstAcess: '/reset-password',
    updateData: '/alterar-dados',
    unAuthenticatedEntryPath: '/sign-in',
    iesSelection: '/ies',
    redirectSSO: '/redirect',
    tourPath: '/',
    locale: 'pt-br',
    enableMock: true,
}

export default appConfig
